import React, { useState } from "react";
import { Menu, Dropdown } from "antd";
import { RgbaStringColorPicker } from "react-colorful";
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

const BgColorDropdown = ({ setNodeBg, isIconVisible = true }) => {
  // const [color, setColor] = useState("rgba(255, 255, 255, 1)");
  const items =
    [
      {
        key: '1',
        label: (
          <div style={{ padding: "10px" }}>
            <RgbaStringColorPicker
              onChange={(selectedColor) => {
                setNodeBg(selectedColor);
                // setVisible(true); 
              }}
            // value={color}
            />
          </div>
        ),
      }
    ]
  return (
    <Dropdown menu={{items}} placement="bottomLeft" trigger={["click"]}>
      <a
        style={{ color: "#626262" }}
      >
        {isIconVisible && (<FormatColorFillIcon style={{ minHeight: "18x", height: "18px", verticalAlign: "top" }} />)}
        {!isIconVisible && (<div style={{ minHeight: "18x", height: "18px", verticalAlign: "top" }} />)}

      </a>
    </Dropdown>
  );
};

export default React.memo(BgColorDropdown);
