import React, { useState } from 'react'
// import {} from 'antd'
import {ReactComponent as ModesIcon} from '../../assets/modes.svg';
import {ReactComponent as DarkIcon} from '../../assets/Dark-Mode.svg';


import {
    enable as enableDarkMode,
    disable as disableDarkMode,
} from 'darkreader';
import { MindMapContext } from '../../Context/contex';




function Modes() {
  const {setDarkMode_}=MindMapContext()

    const [darkMode,setDarkMode]=useState(true)
    // console.log('darkMode : Out ',darkMode)

    const setMode=()=>{

        // console.log('darkMode : ',darkMode)
        // alert('Dark Mode')
        if(darkMode){
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10,
            });
            setDarkMode_(darkMode)
        }else{
            disableDarkMode();

        }
        setDarkMode(!darkMode)
        setDarkMode_(darkMode)
    }
  return (
    <div>
            <div onClick={setMode} style={{ position: "relative", right: "1px", top: "2px", cursor: "pointer", zIndex: 1,opacity:.95 ,}} >
            {darkMode==!true ?
              <ModesIcon style={{width: "26px",height: "26px" }}/>:
              <DarkIcon style={{width: "28px",height: "28px" }}/>}
            </div>
    </div>
  )
}

export default Modes