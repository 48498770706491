import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    addEdge,
    ConnectionMode,
} from 'reactflow';
import 'reactflow/dist/style.css';
import EditNodeModal from '../EditDrawer/index';
import { nodeTypes } from "../Shapes/shapes";
import { v4 } from "uuid";
import { MindMapContext } from '../../Context/contex';
import Sidebar from '../Sidebar/sideBar';
import { MindMapAPI, workwiseServices } from '../../Api/api';
import { useParams } from "react-router-dom";
import { emitEdgeAdd, emitEdgeDelete, emitNodeAdd, emitNodeDelete, emitNodeUpdate, emitRoomID } from '../../Socket/socket';

const MindMapContainer = () => {
    const {
        isReadOnly,
        setSelectedNodeId,
        nodes, setNodes,
        edges, setEdges,
        onEdgesChange, onNodesChange,
        selectedNodeId,
        reactFlowInstance, setReactFlowInstance,
        reactFlowWrapper,
        userInfo, userColor, setUserColor, setSavingData,
        isDocumentChanged, setIsDocumentChanged
    } = MindMapContext();

    const isMobile = window.screen.width < 768
    const { documentId } = useParams();
    const [nodeName, setNodeName] = useState('');
    const [nodeBg, setNodeBg] = useState('#9ca8b3');
    const [nodeHidden, setNodeHidden] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedShape, setSelectedShape] = useState('default');
    const [textBg, setTextBg] = useState('#000');
    const [nodePosition, setNodePosition] = useState({});
    const [selectedNode, setSelectedNode] = useState({})
    const [selectedEdgeId, setSelectedEdgeId] = useState(null);

    useEffect(() => {
        emitRoomID({ documentId });
    }, [documentId]);

    useEffect(() => {
        if (documentId) {
            const defulatNodeID = `default-node-${v4()}`;
            MindMapAPI.fetchMindMapById(documentId).then((res) => {
                let response = res.data;
                if (response.nodes.length === 0) {
                    const initialNodeData = {
                        id: defulatNodeID,
                        label: '',
                        nodeBg: '#fff',
                        textBg: '#000',
                        allocatedColor: userColor
                    };
                    const initialNode = {
                        id: defulatNodeID,
                        type: 'custom',
                        data: initialNodeData,
                        position: { x: 650, y: 250 },
                    };
                    setNodes([initialNode, ...response.nodes]);
                    let data = {
                        creatorId: userInfo.userId,
                        id: defulatNodeID,
                        documentId: documentId,
                        name: nodeName,
                        type: 'custom',
                        position: { x: 650, y: 250 },
                        data: initialNodeData,
                    }
                    MindMapAPI.createNode(data);
                    emitNodeAdd(data);
                }
                else {
                    const nodesWithDimensions = response.nodes.map((node) => ({
                        ...node,
                        data: {
                            ...node.data,
                            width: node.style?.width,
                            height: node.style?.height
                        },
                        position: {
                            x: node.position?.x || 10,
                            y: node.position?.y || 10,
                        }
                    }));
                    setNodes(nodesWithDimensions);
                    setEdges(response.edges);
                    response.nodes.map((node) => {
                        if (node.creatorId === userInfo.userId) {
                            setUserColor(node.data.allocatedColor);
                        }
                    })
                }
            });
        }
    }, []);

    const onConnect = useCallback((params) => {
        const newEdgeId = "edge-" + v4();
        const newEdge = { id: newEdgeId, documentId: documentId, ...params }
        setEdges((eds) => addEdge(newEdge, eds));
        MindMapAPI.createEdge(newEdge);
        emitEdgeAdd(newEdge);
        setIsDocumentChanged(true);
    }, [setEdges]);

    const updateNodeDimensions = (nodeId, dimensions) => {
        reactFlowWrapper.current[nodeId] = dimensions;
    };
    useEffect(() => {
        const nodeElements = document.querySelectorAll('.react-flow__node');
        nodeElements.forEach((nodeElement) => {
            const nodeId = nodeElement.getAttribute('data-id');
            const dimensions = nodeElement.getBoundingClientRect();
            updateNodeDimensions(nodeId, dimensions);
        });
    }, [nodes]);

    const handleNodeClick = (event, node) => {
        setSelectedNode(node);
        setSelectedNodeId(node.id);
        setNodeBg(node.data.nodeBg);
        setNodeName(node.data.label);
        setNodeHidden(node.hidden);
        setEditModalOpen(true);
        setSelectedShape(node.type);
        setTextBg(node.data.textBg);
    };
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback((event) => {
        const newNodeId = "node-" + v4();
        const newNodeData = {
            id: newNodeId,
            label: '',
            nodeBg: '#fff',
            textBg: '#000',
            allocatedColor: userColor
        }
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const proximityThreshold = 100;
        if (typeof type === 'undefined' || !type) {
            return;
        }
        else {
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            let nearestNode = null;
            let minDistance = Number.MAX_VALUE;
            nodes.forEach((node) => {
                const nodeCenterX = node.position.x + node.width / 2;
                const nodeCenterY = node.position.y + node.height / 2;
                const distance = Math.sqrt(
                    Math.pow(position.x - nodeCenterX, 2) + Math.pow(position.y - nodeCenterY, 2)
                );
                if (distance < minDistance) {
                    minDistance = distance;
                    nearestNode = node;
                }
            });
            if (nearestNode && nearestNode.id && minDistance <= proximityThreshold) {
                const newEdge = {
                    id: `edge-${v4()}`,
                    documentId: documentId,
                    source: nearestNode.id,
                    target: newNodeId,
                    sourceHandle: 'bottom',
                    targetHandle: 'top',
                };
                setEdges((edges) => [...edges, newEdge]);
                MindMapAPI.createEdge(newEdge);
                emitEdgeAdd(newEdge);

                const newNodePosition = {
                    x: nearestNode.position.x,
                    y: nearestNode.position.y + nearestNode.height + 20,
                };
                const newNode = {
                    creatorId: userInfo.userId,
                    id: newNodeId,
                    position: newNodePosition,
                    data: newNodeData,
                    type: type,
                    // style: { zIndex: 999 },
                };
                setNodes((nodes) => [...nodes, newNode]);
                const payload = {
                    creatorId: userInfo.userId,
                    id: newNodeId,
                    documentId: documentId,
                    name: nodeName,
                    type: type,
                    position: newNodePosition,
                    data: newNodeData,
                }
                MindMapAPI.createNode(payload);
                emitNodeAdd(payload);
            }
            else {
                const newNode = {
                    creatorId: userInfo.userId,
                    id: newNodeId,
                    position,
                    data: newNodeData,
                    type: type,
                    // style: { zIndex: 999 },
                };
                setNodes((nodes) => [...nodes, newNode]);
                const payload = {
                    creatorId: userInfo.userId,
                    id: newNodeId,
                    documentId: documentId,
                    name: nodeName,
                    type: type,
                    position: position,
                    data: newNodeData,
                    // height: 100,
                    // dragging: false,
                    // selected: false,
                    // resizing: false,
                    // style: { width: 100, height: 100 },
                    // width: 100
                }
                MindMapAPI.createNode(payload);
                emitNodeAdd(payload);
            }
            setIsDocumentChanged(true);
        }
    }, [reactFlowInstance, nodes, setEdges, setNodes]);

    const HandleNodeDrag = (event, node) => {
        if (node && node.position) {
            setNodePosition(node.position);
            setSelectedNodeId(node.id);
        }
    }
    useEffect(() => {
        if (selectedNodeId) {
            let payload = {
                position: nodePosition
            }
            MindMapAPI.updateNode(selectedNodeId, payload);

            let listerPayload = {
                id: selectedNodeId,
                position: nodePosition
            }
            emitNodeUpdate(listerPayload);
            setIsDocumentChanged(true);
        }
    }, [nodePosition])

    const handelNodeDelete = (e, node) => {
        edges.forEach((edge) => {
            if (edge.source === selectedNodeId || edge.target === selectedNodeId) {
                if (edge.id) {
                    MindMapAPI.deleteEdge(edge.id);
                }
            }
        });
        MindMapAPI.deleteNode(selectedNodeId);
        setIsDocumentChanged(true);
        emitNodeDelete(selectedNodeId);
    }
    const handelEdgeDelete = (edge) => {
        MindMapAPI.deleteEdge(selectedEdgeId);
        emitEdgeDelete(selectedEdgeId);
        setIsDocumentChanged(true);
    };

    useEffect(() => {
        const saveDocument = async () => {
            if (isDocumentChanged) {
                const data = {
                    nodes: nodes,
                    edges: edges,
                }
                workwiseServices.saveDocumentlastUpdate(documentId, setSavingData, data);
                setIsDocumentChanged(false);
            }
        };
        saveDocument();
    }, [nodes, edges, isDocumentChanged]);

    return (
        <>
            <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ width: '100vw', height: '90vh', backgroundColor: '#fff' }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    onNodeDoubleClick={handleNodeClick}
                    onNodeClick={(e, node) => { setSelectedNodeId(node.id) }}
                    nodesDraggable={isReadOnly ? false : true}
                    nodesConnectable={isReadOnly ? false : true}
                    connectedOnClick={isReadOnly ? false : true}
                    deleteKeyCode={[isReadOnly ? null : 'Delete']}
                    connectionMode={ConnectionMode.Loose}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    onNodeDragStop={HandleNodeDrag}
                    onNodesDelete={handelNodeDelete}
                    onElementsRemove={handelNodeDelete}
                    onNodeMouseEnter={(e, node) => { setSelectedNodeId(node.id); }}
                    proOptions={{ hideAttribution: true }}
                    onEdgesDelete={handelEdgeDelete}
                    onEdgeClick={(e, node) => { setSelectedEdgeId(node.id); }}
                >
                    {!isReadOnly && (<div className='sideBarDragDropIcons'><Sidebar /></div>)}
                    <div className='mindMap-options'>
                        <Controls />
                        <MiniMap />
                    </div>
                    <Background variant="dots" gap={12} size={1} />
                </ReactFlow>
            </div>
            <EditNodeModal
                selectedNodeId={selectedNodeId} nodes={nodes} edges={edges} setNodes={setNodes} setEdges={setEdges}
                setNodeName={setNodeName} editModalOpen={editModalOpen} setNodeBg={setNodeBg}
                setNodeHidden={setNodeHidden} nodeBg={nodeBg} nodeHidden={nodeHidden}
                nodeName={nodeName} setEditModalOpen={setEditModalOpen} setSelectedShape={setSelectedShape}
                selectedShape={selectedShape} setTextBg={setTextBg} textBg={textBg}
                selectedNode={selectedNode}
            />
        </>
    )
}

export default React.memo(MindMapContainer);