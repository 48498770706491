import React from "react";
import { ShareModal } from "../ShareModal/ShareModal";
import { MindMapContext } from "../../Context/contex";
import { UserAvatar } from "../Avatar/UserAvatar";
import DocumentMembers from "../Members/members";
import UserColor from "../UserColor/userColor";
import Modes from './modes.js'


const UserDetails = () => {
  const { documentInfo,userInfo} = MindMapContext();
  const isMobile=window.screen.width<768
  return (
    <div
      style={{
        width: "fit-content",
        height: "70px",
        display: "flex",
        alignItems: "center",
        gap:isMobile?8:14,
        backgroundColor: "inherit",
        borderRadius: '5px',
        marginRight:'20px'
      }}
    >
      <div style={{ marginRight:isMobile?'':'10px', display: 'flex', justifyContent: 'space-around' }}>
        <Modes />
        <DocumentMembers />
      </div>
      {documentInfo.createBy == userInfo.userId && (
          <>
          <ShareModal />
          </>
        )}
      <UserColor/>
      <UserAvatar/>
    </div>
  );
};

export default UserDetails;
