import React from "react";
import './shapesStyle.css'
import ShapeToNodeType from "./shapeToNodeType";

const RectangleNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={100}
        initialHeight={60}
        type={'rectangle'}
      />
    </>
  );
};
const CircleNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  let shapeStyle = { borderRadius: '50%' }
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={100}
        initialHeight={100}
        type={'circle'}
        shapeStyle={shapeStyle}
      />
    </>
  );
};
const SquareNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={100}
        initialHeight={80}
        type={'square'}
      />
    </>
  );
};

const EllipseNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  let shapeStyle = { borderRadius: '50%' }
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={120}
        initialHeight={80}
        type={'ellipse'}
        shapeStyle={shapeStyle}
      />
    </>
  );
};
const OvalNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  let shapeStyle = { borderRadius: '50%' }
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={68}
        initialHeight={100}
        type={'oval'}
        shapeStyle={shapeStyle}
      />
    </>
  );
};
const ParallelogramNode = ({ data, selected }) => {

  let isSelected = selected;
  let nodeData = data;
  const shapeStyle = { transform: 'skewX(-20deg)' };
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={100}
        initialHeight={60}
        type={'parallelogram'}
        shapeStyle={shapeStyle}
      />
    </>
  );
};
export const TextNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={150}
        initialHeight={35}
        type={'text'}
      />
    </>
  );
};
const CustomNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={150}
        initialHeight={35}
        type={'custom'}
      />
    </>
  );
};
const TriangleNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  const handleBottomLeftStyle = {
    position: "absolute",
    left: 0,
    top: '100%',
    transform: 'translate(-50%, -78%)',
  }
  const handleBottomRightStyle = {
    position: 'absolute',
    left: '100%',
    top: '100%',
    transform: 'translate(-78%, -78%)',
  }
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={160}
        initialHeight={100}
        type={'triangle'}
        isSvg={true}
        handleBottomLeftStyle={handleBottomLeftStyle}
        handleBottomRightStyle={handleBottomRightStyle}
      />
    </>
  )

};
const DiamondNode = ({ data, selected }) => {
  let isSelected = selected;
  let nodeData = data;
  return (
    <>
      <ShapeToNodeType
        data={nodeData}
        isSelected={isSelected}
        initialWidth={100}
        initialHeight={100}
        type={'diamond'}
        isSvg={true}
      />
    </>
  )
};

export const nodeTypes = {
  circle: CircleNode,
  rectangle: RectangleNode,
  triangle: TriangleNode,
  square: SquareNode,
  ellipse: EllipseNode,
  oval: OvalNode,
  diamond: DiamondNode,
  parallelogram: ParallelogramNode,
  custom: CustomNode,
  text: TextNode,
};
