import React from 'react';
import { Skeleton } from 'antd';
import './Loader.css';

const Loader = () => {
    return (
        <>
            <div className='loader-container'>
                <div style={{ display: "flex", justifyContent: "space-between", }} >
                    <div className='loader-header' >
                        <Skeleton.Image active style={{ marginRight: 20, width: "35px", height: '35px' }} />
                        <div style={{ height: 30, width: 2, marginRight: 20, backgroundColor: "#757D86", }}></div>
                        <Skeleton.Image active style={{ marginRight: 5, width: "35px", height: '35px' }} />
                    </div>
                    <div className='loader-other-buttons'>
                        <Skeleton.Image active style={{ marginRight: 5, width: "35px", height: '35px' }} />
                        <Skeleton.Image active style={{ marginLeft: 40, width: "35px", height: '35px' }} />
                    </div>
                </div>
                <div className='loader-main-container '>
                    <Skeleton.Avatar active style={{ position: 'absolute', left: 5, top: '58%', cursor: 'pointer', float: 'right', width: '50px', height: '50px' }} />
                    <div className='loader-control-menu'>
                        <Skeleton.Image active style={{ width: '30px', height: '30px' }}/>
                        <Skeleton.Image active style={{ width: '30px', height: '30px' }}/>
                        <Skeleton.Image active style={{ width: '30px', height: '30px' }}/>
                        <Skeleton.Image active style={{ width: '30px', height: '30px' }}/>
                    </div>
                    <Skeleton.Button active style={{ position: 'absolute', width: '140px', height: '40px', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />
                    <div style={{ position: 'absolute', bottom: 5, right: 8 }}>
                        <Skeleton.Button active style={{ width: '280px', height: '140px' }} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Loader;

