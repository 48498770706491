import React from 'react';
import circle from '../../assets/dropCircle.svg';
import triangle from '../../assets/dropTriangle.svg';
import rectangle  from '../../assets/dropRectangle.svg';
import { MindMapContext } from '../../Context/contex';

const Sidebar = () => {
    const { isReadOnly,darkMode} = MindMapContext();
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    return (
        <div
            style={{
                width: "33px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: 'absolute',
                gap: 8,
                bottom: '135px',
                left:'10px',
                zIndex: 9999,
                pointerEvents: `${isReadOnly ? 'none' : 'all'}`,
                filter:!darkMode?'none':'invert(1)'
            }}
        >
            <img
                title='Add circle'
                src={circle}
                width='22px'
                alt='circle-shape'
                onDragStart={(event) => onDragStart(event, 'circle')} draggable
            />
            <img
                title='Add triangle'
                src={triangle}
                width='22px'
                alt='triangle-shape'
                onDragStart={(event) => onDragStart(event, 'triangle')} draggable
            />
            <img
                title='Add rectangle'
                src={rectangle}
                width='22px'
                alt='rectangle-shape'
                onDragStart={(event) => onDragStart(event, 'rectangle')} draggable
            />
        </div>
    )
}

export default Sidebar;