import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import './handels.css'
import { MindMapContext } from '../../Context/contex';
import { v4 } from 'uuid';
import { useParams } from "react-router-dom";
import { MindMapAPI } from '../../Api/api';
import { emitEdgeAdd, emitNodeAdd } from '../../Socket/socket';

const NodeHandels = ({ handleBottomLeftStyle, handleBottomRightStyle, nodeType, isHovered }) => {

  const { documentId } = useParams();
  const { isReadOnly, nodes, setNodes, setEdges, selectedNodeId, reactFlowInstance,
    reactFlowWrapper,userInfo,userColor,setIsDocumentChanged } = MindMapContext();
  const [handleVisibility, setHandleVisibility] = useState({
    top: true,
    bottom: true,
    left: true,
    right: true,
  });
  const AddNewNode = (event, direction) => {
    event.stopPropagation();
    const currentNode = nodes.find((node) => node.id === selectedNodeId);
    if (!currentNode || !currentNode.id) {
      return;
    }
    const newNodeId = "node-" + v4();
    const newedgeId = "edge-" + v4();
    let sourceHandleDirection, targetHandleDirection;

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNodePosition = {
      x: position.x,
      y: position.y,
    };
    if (direction === 'top') {
      if (nodeType === 'custom'|| nodeType==='parallelogram') {
        newNodePosition.y -= 80;
      }
      else {
        newNodePosition.y -= 135;
      }
      newNodePosition.x -= 20;
      sourceHandleDirection = 'top'
      targetHandleDirection = 'bottom'
    }
    else if (direction === 'bottom') {
      newNodePosition.y += 34;
      newNodePosition.x -= 20;
      sourceHandleDirection = 'bottom'
      targetHandleDirection = 'top'
    }
    else if (direction === 'left') {
      if (nodeType === 'custom') {
        newNodePosition.x -= 180;
        newNodePosition.y += 14;
      }
      else {
        newNodePosition.x -= 140;
      }
      sourceHandleDirection = 'left'
      targetHandleDirection = 'right'
    }
    else if (direction === 'right') {
      newNodePosition.x += 35;
      // newNodePosition.y -= 54;
      sourceHandleDirection = 'right'
      targetHandleDirection = 'left'
    }
    const newNodeData = {
      id: newNodeId,
      label: '',
      nodeBg: '#fff',
      textBg: 'black',
      allocatedColor:userColor
      // width: currentNode.width,
      // height: currentNode.height
    }
    const newNode = {
      creatorId:userInfo.userId,
      id: newNodeId,
      position: newNodePosition,
      data: newNodeData,
      type: nodeType,
    };
    setNodes((nodes) => [...nodes, newNode]);
    let payload =
    {
      creatorId:userInfo.userId,
      id: newNodeId,
      documentId: documentId,
      name: '',
      type: nodeType,
      position: newNodePosition,
      data: newNodeData,
    }
    MindMapAPI.createNode(payload);
    emitNodeAdd(payload);
    const newEdge = {
      id: newedgeId,
      documentId: documentId,
      source: selectedNodeId,
      target: newNodeId,
      sourceHandle: sourceHandleDirection,
      targetHandle: targetHandleDirection,
    };
    setEdges((edges) => [...edges, newEdge]);
    MindMapAPI.createEdge(newEdge);
    emitEdgeAdd(newEdge);
    setIsDocumentChanged(true);
    // setHandleVisibility((prevVisibility) => ({
      //   ...prevVisibility,
    //   [direction]: false,
    // }));
  };
  return (
    <>
      <Handle type="source" position={Position.Top} id={'top'}>
        {!isReadOnly && (
          <div
            title='top'
            className='button-style top-btn-position'
            style={{
              visibility: `${isHovered ? 'visible' : 'hidden'}`,
            }}
            onClick={(e) => AddNewNode(e, 'top')}
          > + </div>
        )}
      </Handle>

      <Handle type="source" position={Position.Bottom} id={'bottom'}>
        {!isReadOnly && (
          <div
            title='bottom'
            className='button-style bottom-btn-position'
            style={{
              visibility: `${isHovered ? 'visible' : 'hidden'}`,
            }}
            onClick={(e) => AddNewNode(e, 'bottom')}
          >+</div>
        )}
      </Handle>

      <Handle type="source" position={Position.Left} id={'left'} style={{ ...handleBottomLeftStyle }}>
        {!isReadOnly && (
          <div
            title='left'
            className='button-style left-btn-position'
            style={{
              pointerEvents: `${isReadOnly ? 'none' : 'all'}`,
              visibility: `${isHovered ? 'visible' : 'hidden'}`,
            }}
            onClick={(e) => AddNewNode(e, 'left')}
          >+</div>
        )}
      </Handle>

      <Handle type="source" position={Position.Right} id={'right'} style={{ ...handleBottomRightStyle }}>
        {!isReadOnly && (
          <div
            title='right'
            className='button-style right-btn-position'
            style={{
              pointerEvents: `${isReadOnly ? 'none' : 'all'}`,
              visibility: `${isHovered ? 'visible' : 'hidden'}`,
            }}
            onClick={(e) => AddNewNode(e, 'right')}
          >+</div>
        )}
      </Handle>
    </>
  )
}

export default NodeHandels;