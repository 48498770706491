
import axios from 'axios';
import { formatFileSize, getWorkWiseAuthToken } from '../Utils/helper';

export const serverUrl = "https://map.workw.com";
// localServer
// export const serverUrl = "http://localhost:3001";

let docId;
let debounceTimeout;
export const MindMapAPI =
{
  async fetchMindMapById(documentId) {
    try {
      docId = documentId
      const res = await axios.get(`${serverUrl}/api/document/${documentId}`);
      return res;

    } catch (error) {
      console.error('Error while Fetching MindMap', error);
    }
  },
  async fetchAllNodes() {
    try {
      const res = await axios.get(`${serverUrl}/api/node/getAll`);
      return res;

    } catch (error) {
      console.error('Error while Fetching All Nodes', error);
    }
  },
  async fetchAllEdges() {
    try {
      const res = await axios.get(`${serverUrl}/api/edge/getAll`);
      return res;

    } catch (error) {
      console.error('Error while Fetching All Edges', error);
    }
    return
  },
  async createNode(payload) {
    try {
      const res = axios.post(`${serverUrl}/api/node/create`, payload);
      return res;
    } catch (error) {
      console.error('Error while creating node.', error)
    }
  },
  async createEdge(payload) {
    try {
      const res = axios.post(`${serverUrl}/api/edge/create`, payload);
      return res;
    } catch (error) {
      console.error('Error while creating node.', error)
    }
  },
  async updateNode(nodeId, payload) {
    try {
      const res = axios.patch(`${serverUrl}/api/node/update/${nodeId}`, payload);
      return res;
    } catch (error) {
      console.error('Error while creating node.', error)
    }
  },
  async updateEdge(edgeId) {
    try {
      const res = axios.post(`${serverUrl}/api/edge/update/${edgeId}`);
      return res;
    } catch (error) {
      console.error('Error while creating node.', error);
    }
  },
  async updateMany(payload, setSavingData) {
    try {
      const res = axios.patch(`${serverUrl}/api/node/update-multiple/`, payload);
      return res;
    } catch (error) {
      console.error('Error while creating node.', error);
    }
  },
  async deleteNode(nodeId) {
    try {
      const res = axios.delete(`${serverUrl}/api/node/delete/${nodeId}`);;
      return res;
    } catch (error) {
      console.error('Error while creating node.', error);
    }
  },
  async deleteEdge(edgeId) {
    try {
      const res = axios.delete(`${serverUrl}/api/edge/delete${edgeId}`);
      return res;
    } catch (error) {
      console.error('Error while creating node.', error);
    }
  },
}
export const workwiseUrl = "https://workw.com/workwapi/api";

const workwiseHeaders = { 'Authorization': `Bearer ${getWorkWiseAuthToken()}` };

export const workwiseServices = {

  async getDocumentById(id) {
    return axios.get(
      `${workwiseUrl}/Authorize/Authorize?id=${id}`,
      { headers: workwiseHeaders });
  },
  async getAllSuggestion(payload) {
    return axios.post(
      `${workwiseUrl}/Suggestion/GetAllSuggestion`,
      payload,
      { headers: workwiseHeaders });
  },
  async addDocumentMember(id, payload) {
    return axios.post(
      `${workwiseUrl}/Document/AddDocumentMember?id=${id}`,
      payload,
      { headers: workwiseHeaders });
  },
  async shareDocumentToAnyone(payload) {
    return axios.post(`${workwiseUrl}/Document/UpdateDocument`,
      payload,
      { headers: workwiseHeaders }
    )
  },
  async saveDocumentlastUpdate(id, setSavingData, data) {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString();
    const size = new TextEncoder().encode(JSON.stringify(data)).length || 0;
    const sizeWithUnit = formatFileSize(size);

    if (setSavingData) {
      setSavingData({ isSaving: 'Saving...', lastSavedDateTime: '' });

      await new Promise(resolve => setTimeout(resolve, 800));

      setSavingData({
        isSaving: 'Saved',
        lastSavedDateTime: formattedDate,
        fileSize: sizeWithUnit
      });
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        await axios.post(`${workwiseUrl}/Document/SaveDocumentAttachment?attachmentId=${id}&fileSize=${size}`,
          {},
          { headers: workwiseHeaders }
        );
        // Handle the response if needed
      } catch (e) {
        console.error("error", e);
      }
    }, 5000);
  },
  async completeDocument(id) {
    try {
      const formData = new FormData();
      formData.append('id', id);

      await axios.post(`${workwiseUrl}/Document/CompleteDocument?id=${id}`,
        formData,
        { headers: workwiseHeaders }
      );
      // Handle the response if needed
    } catch (e) {
      console.error("error", e);
    }
  },
};

