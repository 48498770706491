import React, { useEffect } from 'react';
import { MindMapContext } from '../../Context/contex';
import {
    listenEdgeAdd,
    listenEdgeAddOff,
    listenEdgeDelete,
    listenEdgeDeleteOff,
    listenNodeAdd, listenNodeAddOff,
    listenNodeDelete, listenNodeDeleteOff,
    listenNodeUpdate, listenNodeUpdateOff, listenUpdateMultiple, listenAllocateColor
} from '../../Socket/socket';

const SocketListener = () => {
    const { setNodes, edges, setEdges } = MindMapContext();
    useEffect(() => {
        listenNodeAdd(data => {
            setNodes(nodes => [data, ...nodes]);
        });
        //If Neccessary, will be uncommented

        // listenUpdateMultiple((data)=>{
        //     console.log('data From Listener',data)
        //     setNodes((data) =>
        //     data.map((node) => {
        //       return node;
        //     })
        //   );
        // })

        listenAllocateColor((data) => {
            if (data.nodeBg) {
                setNodes((nds) =>
                    nds.map((node) => {
                        if (node.creatorId === data.userId) {
                            node.data.allocatedColor = data.nodeBg;
                        }
                        return node;
                    })
                );
            }
        })

        listenEdgeAdd((data) => {
            setEdges((edge) => [data, ...edge,]);
        });
        listenNodeDelete((selectedNodeId) => {
            setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
        });
        listenNodeUpdate((data) => {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === data.id) {
                        if (data.position) {
                            node.position = data.position;
                        }
                        else if (data.name || data.name == '') {
                            node.data = {
                                ...node.data,
                                label: data.name,
                            };
                        }
                        else if (data.bg) {
                            node.data = {
                                ...node.data,
                                nodeBg: data.bg,
                            };
                        }
                        else if (data.textBg) {
                            node.data = {
                                ...node.data,
                                textBg: data.textBg,
                            };
                        }
                        else if (data.type) {
                            node.type = data.type;
                        }
                        else if (data.PrevData) {
                            node.data = data.PrevData;
                            node.type = data.prevNodeType;
                        }
                        else if (data.style) {
                            let { width, height } = data.style;
                            if (node && node.data && width && height) {
                                node.data = {
                                    ...node.data,
                                    width: width,
                                    height: height
                                };
                                if (node.style && node.style.width && node.style.height) {
                                    node.style.width = data.style.width;
                                    node.style.height = data.style.height;
                                    if (node.width && node, height) {
                                        node.width = data.style.width;
                                        node.height = data.style.width
                                    }
                                }
                                if (data.nodePosition) {
                                    node.position = data.nodePosition;
                                }
                            }
                        }
                    }
                    return node;
                })
            );
        });

        listenEdgeDelete(data => {
            const updatedEdges = edges.filter(edge => edge.id !== data);
            setEdges(updatedEdges);
        });

        return () => {
            listenNodeAddOff();
            listenNodeUpdateOff();
            listenEdgeAddOff();
            listenEdgeDeleteOff();
            listenNodeDeleteOff();
        };
    }, [edges]); // Added edges to the dependency array to ensure it updates properly

    return <div></div>;
};

export default SocketListener;
// 