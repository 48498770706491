import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWorkWiseAuthToken } from "../src/Utils/helper";
import { workwiseServices } from "./Api/api"; 
import { MindMapContext } from "./Context/contex";
import Loader from "./Comp/SkeletonLoader/Loader";
import jwtDecode from "jwt-decode";

export const AuthenticateDocument = ({ setIsLoading }) => {
  
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { setDocumentInfo, setIsReadOnly,setUserInfo} = MindMapContext();

  const handleAuhenticate = async () => {
    try {
      setIsLoading(true);
      const searchParams = new URLSearchParams(document.location.search)
      const isReadOnlyQuery = searchParams.get("readOnly")
      if (isReadOnlyQuery == "true") {
        setIsReadOnly(true);
      }
      if (!window.navigator.onLine) {
        window.location.pathname="/no-internet"
        return;
      }
      else{
        if (!!getWorkWiseAuthToken()) {
          const response = await workwiseServices.getDocumentById(documentId);
          if (response?.status==200 && response.data?.data?.rightType !== 0 && response.data?.data) {
            setIsLoading(false);
            setDocumentInfo(response.data.data);
            if (response.data.data.rightType == 1) {
              setIsReadOnly(true);
            }
          }
          if(response.data?.data?.rightType == 0)
          {
            window.location.pathname="/unauthorized"
          }
          // history.push("/unauthorized")
        } 
        else {
          window.location.pathname="/unauthorized"
        }
      }
    } catch (error) {
      console.log({ error })
      if (error.response?.data?.responseCode === 1008) {
        window.location.pathname="/invalid-document"
      }
      else
      {
        window.location.pathname="/unauthorized"
      }
    }
  }

  useEffect(() => {
    handleAuhenticate()
    setUserInfo(jwtDecode(getWorkWiseAuthToken()));
  }, []);

  return<Loader/>

}