import { Avatar, Badge } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import './members.css';
import { MindMapContext } from '../../Context/contex';

const DocumentMembers = () => {
    const { documentInfo, userInfo } = MindMapContext();
    const [onlineUsers, setOnlineUsers] = useState([]);
    const colorOptions = useMemo(() => ['#f56a00', '#7265e6', '#ffbf00', '#87d068', '#ff6b81', '#00a2ae'], []);
    const [memberColors, setMemberColors] = useState({});

    const getInitials = (name) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        // renderMouse(setOnlineUsers);
        // connectedClients(setConnectedSocketID);
    }, []);

    const getRandomColor = (memberId) => {
        if (memberColors[memberId]) {
            return memberColors[memberId];
        }

        let newColor;
        do {
            newColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
        } while (newColor === '#CF9FFF' || Object.values(memberColors).includes(newColor));

        setMemberColors((prevColors) => ({
            ...prevColors,
            [memberId]: newColor,
        }));

        return newColor;
    };

    return (
        <Avatar.Group
            maxCount={4}
            maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
            }}
        >
            {documentInfo?.members?.length >= 1 && documentInfo.members.map((member, ind) => {
                if (userInfo.userId !== member.id) {
                    const backgroundColor = getRandomColor(member.id);
                    return (
                        <Badge
                            key={ind}
                            offset={["-6%", "88%"]}
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "green"
                            }}
                            // dot={onlineUsers.some(onlineUser=> onlineUser.userName == member.name)}
                        >
                            <Avatar
                                src={member.image}
                                style={{
                                    backgroundColor: backgroundColor,
                                    width:'35px',
                                    height:'35px',
                                    lineHeight:'35px',
                                    fontSize:'14px'
                                }}
                            >
                                {getInitials(member.name)}
                            </Avatar>
                        </Badge>
                    );
                }
            })}
        </Avatar.Group>
    );
}

export default DocumentMembers;
