import React from 'react'

const TriangleShape = ({nodeBg,nodeWidth,nodeHeight,borderColor}) => {
    const originalPoints = `0 ${nodeHeight},${nodeWidth} ${nodeHeight},${nodeWidth / 2} 0`;
    return (
        <>
            <svg
                width='100%'
                height='100%'
                viewBox={`0 0 ${nodeWidth} ${nodeHeight}`}
            >
                <polygon
                    points={originalPoints}
                    fill={nodeBg ? nodeBg : "#fff"}
                    stroke={borderColor}
                    strokeWidth="1"
                />
            </svg>
        </>
    )
}

export default TriangleShape;