import { Avatar, Image,Button,message } from "antd";
import iconImage from "../../../assets/iconImage.png";
import classes from "./SharePrivacy.module.css";
// import "antd/dist/antd.css";
import { DropDownMenu } from "./DropDownMenu/DropDownMenu";
import ShareForm from "./ShareForm";
import { workwiseServices } from "../../../Api/api";
import { MindMapContext } from "../../../Context/contex";
import { useParams } from "react-router-dom";


export const SharePrivacy = ({ contentToggle,setIsModalVisible,privacy,setPrivacy }) => {
  const {setDocumentInfo,documentInfo,setUserInfo}=MindMapContext();
  console.log({documentInfo})

  const mapName=documentInfo.name;
  const { documentId } = useParams();
const id=documentId;
  const privacyId=1
  const payload={attachmentId: id,name : mapName,privacyId}

  const callShareDocumentToAnyone=async()=>{
    await workwiseServices.shareDocumentToAnyone(payload)
     message.success("Document is shared as Public")
     setIsModalVisible(false)
 
     const response = await workwiseServices.getDocumentById(id);
     if (response?.status==200 && response.data?.data?.rightType !== 0 && response.data?.data) {
 
       setDocumentInfo(response.data.data)
     }
 
   }
  
  return (

    <div className={classes.ownerInfo}>
      <div className={classes.avatar}>
        <Avatar size="large" src={<Image src={iconImage} />} />
      </div>
      <div className={classes.info}>
        <div className={classes.name}>
          {contentToggle ? (
            <h4>Name</h4>
          ) : (
            <DropDownMenu
              menuItems={["Restricted", "Anyone in the Company"]}
              placement="bottomLeft"
              type={privacy}
              setType={setPrivacy}
            />
          )}
        </div>
        <div className={classes.email}>
          {privacy === "Restricted" ? (
            <>
              <ShareForm setIsModalVisible={setIsModalVisible} setPrivacy={setPrivacy} />
            </>
          ) : (
            <>
              <p>Anyone in the Company with the link can access
              </p>
              <Button style={{ marginTop: 10, marginLeft: 'auto', display: 'block' }} size="small" shape="round" onClick={callShareDocumentToAnyone} className='share-button' type="primary" htmlType="submit">
                Share
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
