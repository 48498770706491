import React, { useEffect, useState } from 'react';
import Resizer from '../Resizer/resizer';
import NodeInput from '../TextBox/textBox';
import NodeHandels from '../NodeHandel/handels';
import DimoandShape from './SvgShapes/dimoandShape';
import TriangleShape from './SvgShapes/triangleShape';
import { MindMapContext } from '../../Context/contex';

const ShapeToNodeType = React.memo(({
    data, initialWidth, initialHeight,
    type, mainContainerStyle, shapeStyle, isSelected,
    isSvg, handleBottomLeftStyle, handleBottomRightStyle
}) => {
    let { id, nodeBg, height, width,allocatedColor } = data;
    allocatedColor=allocatedColor?allocatedColor:'#000';
    const [nodeWidth, setNodeWidth] = useState(width || initialWidth);
    const [nodeHeight, setNodeHeight] = useState(height || initialHeight);
    const [isHovered, setIsHovered] = useState(false);
    const { userColor,setUserColor } = MindMapContext();
    let nodeData = data;
    let inputStyle = { transform: type === 'parallelogram' ? 'skewX(20deg)' : '' }

    useEffect(() => {
        if (width) {
            setNodeWidth(width);
        }
        if (height) {
            setNodeHeight(height);
        }

    }, [height], [width]);

    const containerStyle = {
        width: nodeWidth,
        height: nodeHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // position: 'relative',
        border: `1px solid ${allocatedColor}`,
        backgroundColor: nodeBg,
    };
    if (type === 'text' || type === 'diamond' || type === 'triangle') {
        containerStyle.border = 'none';
        containerStyle.backgroundColor = 'transparent';
    }
    const inputContainerStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    };
    const svgContainerStyle = {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
    };
    return (
        <div
            id={id}
            style={{
                ...mainContainerStyle,
                ...containerStyle,
                ...shapeStyle
            }}
        >
            <Resizer
                width={nodeWidth}
                height={nodeHeight}
                setWidth={setNodeWidth}
                setHeight={setNodeHeight}
                isSelected={isSelected}
            />
            {isSvg && (
                <>
                    {type === 'diamond' && (
                        <div style={svgContainerStyle}>
                            <DimoandShape nodeBg={nodeBg} nodeWidth={nodeWidth} nodeHeight={nodeHeight}
                                borderColor={allocatedColor} />
                        </div>
                    )}
                    {type === 'triangle' && (
                        <div style={svgContainerStyle}>
                            <TriangleShape nodeBg={nodeBg} nodeWidth={nodeWidth} nodeHeight={nodeHeight}
                                borderColor={allocatedColor}
                            />
                        </div>
                    )}
                </>
            )}
            <div
                style={{
                    width: isHovered ? `${nodeWidth + 36}px` : `${nodeWidth}px`,
                    marginLeft: isHovered ? '-20px' : '0px',
                    marginRight: isHovered ? '-20px' : '0px',
                    height: isHovered ? `${nodeHeight + 30}px` : `${nodeHeight}px`,
                    // border: '1px solid green',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div style={inputContainerStyle}>
                    <NodeInput data={nodeData} inputStyle={inputStyle} />
                </div>
                {type !== 'text' && (<NodeHandels id={id} nodeType={type} isHovered={isHovered} handleBottomLeftStyle={handleBottomLeftStyle} handleBottomRightStyle={handleBottomRightStyle} />)}
            </div>
        </div>
    );
});

export default ShapeToNodeType;
