import React, { useState, useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import { MindMapContext } from '../../Context/contex';
import { debounce } from "lodash";
import { MindMapAPI } from '../../Api/api';
import { emitNodeUpdate } from '../../Socket/socket';

const { TextArea } = Input;

const NodeInput = ({ data, inputStyle, id }) => {
  const { isReadOnly, setNodes, setSavingData, selectedNodeId, setIsDocumentChanged } = MindMapContext();
  const [nodeName, setNodeName] = useState(data.label);

  useEffect(() => {
    setNodeName(data.label);
  }, [data.label]);

  const updateNodeName = useRef(debounce((selectedNodeId, payload) => {
    MindMapAPI.updateNode(selectedNodeId, payload);
    let emitPayload = {
      id: selectedNodeId,
      name: payload?.data?.label,
    };
    emitNodeUpdate(emitPayload);
    setIsDocumentChanged(true);
  }, 400)).current;

  const handleChange = (event) => {
    setSavingData({ isSaving: 'Saving...', lastSavedDateTime: '' });
    const newValue = event.target.value;
    let payload;
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            label: newValue,
          };
          payload = {
            data: {
              ...node.data,
              label: newValue,
            }
          }
        }
        return node;
      })
    );
    updateNodeName(selectedNodeId, payload);
  };
  const handleTextAreaFocus = (event) => {
    event.target.setSelectionRange(0, 0);
  };

  return (
    <>
      <Form>
        <Form.Item>
          <TextArea
            placeholder="Enter text"
            value={nodeName}
            onChange={(event) => handleChange(event)}
            onFocus={(event) => handleTextAreaFocus(event)}
            autoSize={{ minRows: 2 }}
            readOnly={isReadOnly ? true : false}
            style={{
              ...inputStyle,
              textAlign: 'center',
              padding: '0',
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              color: data.textBg,
            }}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default NodeInput;
