import React from 'react';
import './printMindMap.css';
import printIcon from '../../assets/printer-icon.svg';
import { useReactToPrint } from "react-to-print";
import { MindMapContext } from '../../Context/contex';

const PrintMindMap = () => {
    const { reactFlowWrapper } = MindMapContext();
    
    const handlePrint = useReactToPrint({
        content: () => reactFlowWrapper.current,
    });

    return (
        <div onClick={(e) => handlePrint(e)} style={{ cursor: 'pointer', marginTop: '8px', marginLeft: "8px" }}>
            <img src={printIcon} width={22} />
        </div>
    );
}

export default PrintMindMap;