import React, { useEffect, useRef, useState } from 'react';
import 'reactflow/dist/style.css';
import './index.css';
import { Button, Drawer, Form, Input, Checkbox, } from 'antd';
import BgColorDropdown from '../BgColorDropdown/bgDropdown';
import ShapeDropdown from '../Shapes/ShapeDropdown';
import { MindMapAPI } from '../../Api/api';
import { emitNodeDelete, emitNodeUpdate } from '../../Socket/socket';
import { debounce } from "lodash";
import { MindMapContext } from '../../Context/contex';

const EditNodeModal = ({
  selectedNodeId,
  setNodes,
  setEdges,
  editModalOpen,
  setNodeName,
  setNodeBg,
  setNodeHidden,
  nodeName,
  nodeBg,
  nodeHidden,
  setEditModalOpen,
  setSelectedShape,
  selectedShape,
  setTextBg,
  textBg,
  nodes,
  selectedNode,
  edges,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const { setIsDocumentChanged } = MindMapContext()

  const updateNodeName = useRef(debounce((payload) => {
    emitNodeUpdate(payload);
  }, 400)).current;

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            label: nodeName,
          };
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      name:nodeName
    }
    updateNodeName(payload);
  }, [nodeName]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.type = selectedShape;
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      type:selectedShape
    }
    emitNodeUpdate(payload);
  }, [selectedShape]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            nodeBg: nodeBg,
          };
          node.style = { ...node.style, backgroundColor: nodeBg };
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      bg:nodeBg
    }
    emitNodeUpdate(payload);
  }, [nodeBg]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = {
            ...node.data,
            textBg: textBg,
          };
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      textBg:textBg
    }
    emitNodeUpdate(payload);
  }, [textBg]);

  // useEffect(() => {
  //   setNodes((nds) =>
  //     nds.map((node) => {
  //       if (node.id === selectedNodeId) {
  //         node.hidden = nodeHidden;
  //       }
  //       return node;
  //     })
  //   );
  //   setEdges((eds) =>
  //     eds.map((edge) => {
  //       if (edge.id === 'e1-2') {
  //         edge.hidden = nodeHidden;
  //       }

  //       return edge;
  //     })
  //   );
  // }, [nodeHidden]);

  const handleCancel = () => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeId) {
          node.data = selectedNode.data;
          node.type = selectedNode.type;
        }
        return node;
      })
    );
    let payload ={
      id:selectedNodeId,
      PrevData:selectedNode.data,
      prevNodeType:selectedNode.type
    }
    emitNodeUpdate(payload);
    setEditModalOpen(false);
  };
  const handleDelete = () => {
    const filteredEdges = edges.filter(
      (edge) => edge.source !== selectedNodeId && edge.target !== selectedNodeId
    );
    setEdges(filteredEdges);
    setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
    edges.forEach((edge) => {
      if (edge.source === selectedNodeId || edge.target === selectedNodeId) {
        if (edge.id) {
          MindMapAPI.deleteEdge(edge.id);
        }
      }
    });
    MindMapAPI.deleteNode(selectedNodeId);
    emitNodeDelete(selectedNodeId);
    setIsDocumentChanged(true);
    setEditModalOpen(false);
  };


  const updateNode = (selectedNodeId) => {
    let payload = {
      data: {
        label: nodeName,
        nodeBg: nodeBg,
        textBg: textBg,
      },
      type: selectedShape
    }
    MindMapAPI.updateNode(selectedNodeId, payload);
    // let emitPayload ={
    //   id:selectedNodeId,
    //   newData:payload.data,
    //   newNodeType:payload.type
    // }
    // emitNodeUpdate(emitPayload);
    setIsDocumentChanged(true);
    setEditModalOpen(false);
  }

  return (
    <Drawer
      title="Edit Node"
      placement="right"
      className='no-blur-container'
      open={editModalOpen}
      onClose={handleCancel}
      maskClosable={false}
      style={{ height: 'fit-content' }}
    >
      <Form>
        <div className="" style={{ marginTop: '-10px' }}>
          <Form.Item label="Name:">
            <Input value={nodeName} onChange={(evt) => setNodeName(evt.target.value)} />
          </Form.Item>
          <Form.Item label='Background Color:' style={{ marginTop: '-5px' }}>
            <BgColorDropdown setNodeBg={setNodeBg} setIsSelected={setIsSelected} />
          </Form.Item>
          <Form.Item label='Text Color:' style={{ marginTop: '-5px' }}>
            <BgColorDropdown setNodeBg={setTextBg} setIsSelected={setIsSelected} />
          </Form.Item>
          <Form.Item label='Shapes:' style={{ marginTop: '-5px' }}>
            <ShapeDropdown selectedShape={selectedShape} setSelectedShape={setSelectedShape} />
          </Form.Item>
          {/* <Form.Item label='Hidden:'>
            <div className="">
              <Checkbox
                type="checkbox"
                checked={nodeHidden}
                onChange={(evt) => setNodeHidden(evt.target.checked)}
              />
              {nodeHidden && <VisibilityOffIcon />}
            </div>
          </Form.Item> */}
          <div style={{ display: 'flex', gap: 40, alignItems: 'center', marginLeft: '40px', marginTop: '32px' }}>
            <Form.Item>
              <Button
                type="primary"
                size="medium"
                className="btn-style"
                style={{ background: '#526bb1' }}
                block
                htmlType="submit"
                onClick={() => {
                  updateNode(selectedNodeId);
                }}
              >
                Update
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="medium"
                className="btn-style"
                style={{ background: 'rgb(219, 82, 82)' }}
                block
                onClick={() => {
                  handleDelete();
                }}
                danger>
                Delete
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default EditNodeModal;
