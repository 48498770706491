import React from 'react';
import { Select } from 'antd';
import squareShape from '../../assets/shapes/Squre2.svg';
import circleShape from '../../assets/shapes/basicCircle.svg';
import triangleShape from '../../assets/shapes/basicTriangle.svg';
import ellipseShape from '../../assets/shapes/ellipse.svg';
import rectangleShape from '../../assets/shapes/basicRectangle.svg';
import diamondShape from '../../assets/shapes/diamond.svg';
import OvalShape from '../../assets/shapes/oval.png';
import parallelogramShape from '../../assets/shapes/parallelogram.svg';
const { Option } = Select;

const ShapeDropdown = ({ selectedShape, setSelectedShape }) => {
  const handleShapeChange = (value) => {
    setSelectedShape(value);
  };
  return (
    <Select value={selectedShape} onChange={handleShapeChange}>
      <Option value="custom">Custom</Option>
      <Option value="text">Plain Text</Option>
      <Option value="square"><img src={squareShape} style={{ float: 'right', width: '30px', marginTop: '2px' }} />Square</Option>
      <Option value="ellipse"><img src={ellipseShape} style={{ float: 'right', width: '40px', marginTop: '2px' }} />Ellipse</Option>
      <Option value="parallelogram"><img src={parallelogramShape} style={{ float: 'right', width: '30px', marginTop: '2px' }} />Parallelogram</Option>
      <Option value="circle"> <img src={circleShape} style={{ float: 'right', width: '30px' }} />Circle</Option>
      <Option value="diamond"><img src={diamondShape} style={{ float: 'right', width: '30px' }} />Diamond</Option>
      <Option value="oval"> <img src={OvalShape} style={{ float: 'right', width: '30px' }} /> Oval</Option>
      <Option value="rectangle"><img src={rectangleShape} style={{ float: 'right', width: '30px' }} />Rectangle</Option>
      <Option value="triangle"><img src={triangleShape} style={{ float: 'right', width: '30px' }} />Triangle</Option>
    </Select>
  );
};

export default ShapeDropdown;
