import React, { useState } from 'react';
import Title from './Comp/Header/Header';
import MindMapContainer from './Comp/MindMapContainer/MindMapContainer';
import UserDetails from './Comp/UserDetail/UserDetails';
import { AuthenticateDocument } from './AuthenticateDocument';
import { ReactFlowProvider } from 'reactflow';
import { ChatBox } from './Comp/ChatBox/ChatBox';
import SocketLister from './Comp/SokcetListner/socketLister';
import { useParams } from 'react-router-dom';
export default function App() {
  const { documentId } = useParams();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  if (isAuthenticating) {
    return <AuthenticateDocument setIsLoading={setIsAuthenticating} />
  }
  else {
    return (
      <div>
        <ReactFlowProvider>
          <div style={{ display: "flex", justifyContent: "space-between", }} className='my-header'>
            <Title />
            <UserDetails />
          </div>
          <div style={{ marginTop: '6px', display: 'flex' }}>
            <MindMapContainer />
          </div>
        </ReactFlowProvider>
        <div className='chatbox-container'>
          <ChatBox id={documentId} />
        </div>
        <SocketLister />
      </div>
    );
  }
}
